<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">Language Information</h6>
    </div>
    <div v-if="LanguageSpokenInfo.length">
      <CRow class="pb-2 profileView">
        <CCol lg="6" v-for="(data, index) in LanguageSpokenInfo" :key="index">
          <label class="text-gray"
            >{{ data.language + `${data.proficiency ? ` - ${data.proficiency}` : " - --" }` }}</label
          >
        </CCol>
      </CRow>
    </div>
    <div v-else class="text-center">
      <label class="text-gray">No Languages Found</label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CandidateLanguageInfo",
  computed: {
    ...mapGetters(["getCandidateLanguage"]),
    LanguageSpokenInfo() {
      return (
        this.getCandidateLanguage.map(
          ({
            language_id,
            candidate_language_id,
            language,
            language_proficiency,
            comments,
          }) => {
            return {
              language_id: language.language_id,
              candidate_language_id: candidate_language_id,
              language: language.language,
              proficiency: language_proficiency?.proficiency,
              comments: comments,
            };
          }
        ) || []
      );
    },
  },
};
</script>
