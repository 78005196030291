var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"cand-info-img text-center m-auto pb-1"},[_c('img',{staticClass:"rounded-circle",attrs:{"src":_vm.generateFullUrlWithToken(_vm.profile.profile_picture),"alt":""},on:{"error":function($event){$event.target.src = '/img/user_default_pic.png'}}})]),_c('div',{staticClass:"text-center"},[_c('h5',{staticClass:"m-0",style:(`color: ${_vm.getGenderColor(_vm.profile.gender)}`)},[_vm._v(" "+_vm._s(_vm._f("toTitleCase")(_vm.profile._title))+" "+_vm._s(_vm._f("toTitleCase")(_vm.profile.first_name))+" "+_vm._s(_vm._f("toTitleCase")(_vm.profile.surname))+" ")]),(_vm.profile.candidateTypeName)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.profile.candidateTypeName)+" ")]):_vm._e(),_c('p',{staticClass:"mb-1"},[(_vm.profile.nationalityName)?_c('span',[_vm._v(" "+_vm._s(_vm.profile.nationalityName)+" ")]):_vm._e(),(_vm.profile.genderName)?_c('span',[_vm._v(", "+_vm._s(_vm.profile.genderName))]):_vm._e(),(_vm.profile.age)?_c('span',{staticClass:"nowrap"},[_vm._v(", "+_vm._s(_vm.profile.age)+" Years")]):_vm._e()]),_c('div',{staticClass:"pb-2"},[_c('span',{staticClass:"text-muted"},[_vm._v("Candidate ID : ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.profile.display_uid))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.profile.original_ohr_id != null),expression:"profile.original_ohr_id != null"}],staticClass:"pb-2"},[_c('span',{staticClass:"text-muted"},[_vm._v("Old CRM ID : ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.profile.original_ohr_id))])]),(!_vm.isUKMainAgency && _vm.isOrgBadge && !_vm.isAgencyBadge)?_c('div',{staticClass:"mb-2"},[_c('CBadge',{staticClass:"p-1",attrs:{"color":"danger"}},[_vm._v(_vm._s(_vm.profile.organisation_name)+" ")]),_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
        content: _vm.toolTip,
        appendToBody: true,
        placement: 'right',
      }),expression:"{\n        content: toolTip,\n        appendToBody: true,\n        placement: 'right',\n      }"}],staticClass:"three-dots mx-1"},[_c('i',{staticClass:"fa-solid fa-ellipsis-vertical"})])],1):_vm._e(),(
        _vm.currentUserRole !== _vm.Role.systemCandidate &&
        _vm.currentUserRole !== _vm.Role.customerCandidate &&
        !_vm.isCandidateSupplierFromAccessToken
      )?_c('div',{staticClass:"pb-1"},[_c('span',{staticClass:"text-primary",style:('font-size: 12px')},[_vm._v("Registered: "+_vm._s(_vm.profile.createdOnDate)+" ")])]):_vm._e(),(
        _vm.currentUserRole !== _vm.Role.systemCandidate &&
        _vm.currentUserRole !== _vm.Role.customerCandidate &&
        !_vm.isCandidateSupplierFromAccessToken
      )?_c('div',{staticClass:"pb-2"},[_c('span',{staticClass:"text-primary",style:('font-size: 12px')},[_vm._v("Last Login: "+_vm._s(_vm.profile.lastLoginDate)+" ")])]):_vm._e(),_c('CRow',{staticClass:"justify-content-center"},[(_vm.latestResume && !_vm.isJobSupplier && !_vm.isHeadlessClient)?_c('a',[_c('CButton',{staticClass:"px-2 f-12 mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.preview(_vm.latestResume)}}},[_vm._v(" "+_vm._s(_vm.latestResume.lable)+" "),_c('i',{staticClass:"fas fa-eye ml-2"})])],1):_vm._e(),(_vm.latestAgencyCV && _vm.isJobSupplier)?_c('a',[_c('CButton',{staticClass:"px-2 f-12 mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.preview(_vm.latestAgencyCV)}}},[_vm._v(" "+_vm._s(_vm.latestAgencyCV.lable)+" "),_c('i',{staticClass:"fas fa-eye ml-2"})])],1):(_vm.isJobSupplier && !_vm.latestAgencyCV)?_c('CButton',{staticClass:"px-2 f-12 mr-2",attrs:{"name":"btn_req_agency_cv","color":"primary","disabled":_vm.isAgencyCVReqPending},on:{"click":_vm.openReqAgencyModal}},[_vm._v(" Request Agency CV ")]):_vm._e(),(!_vm.isCandidateRole && !_vm.isCandidateSupplierFromAccessToken)?_c('CButton',{staticClass:"px-2 f-12",attrs:{"color":"primary"},on:{"click":_vm.openFrontSheet}},[_vm._v(" Frontsheet "),_c('i',{staticClass:"fas fa-download ml-2"})]):_vm._e()],1),(_vm.currentUserRole === _vm.Role.systemAdmin)?_c('CRow',{staticClass:"mt-2 justify-content-center"},[(!_vm.profile.deleted)?_c('CButton',{staticClass:"px-2 f-12 col-4",attrs:{"name":"Delete","color":"primary"},on:{"click":function($event){return _vm.candidateState($event)}}},[_vm._v("Delete")]):_vm._e(),(_vm.profile.deleted)?_c('CButton',{staticClass:"px-2 f-12 col-4",attrs:{"name":"Restore","color":"primary"},on:{"click":function($event){return _vm.candidateState($event)}}},[_vm._v("Restore")]):_vm._e(),(
          (_vm.profile.available_during_covid ||
            _vm.profile.available_during_covid === null) &&
          !_vm.profile.deleted
        )?_c('CButton',{staticClass:"ml-2 f-12 px-1 col-4",attrs:{"name":"Deactivate","variant":"outline","color":"primary"},on:{"click":function($event){return _vm.candidateState($event)}}},[_vm._v("Deactivate")]):_vm._e(),(_vm.profile.available_during_covid === false && !_vm.profile.deleted)?_c('CButton',{staticClass:"ml-2 px-1 f-12 col-4",attrs:{"name":"Reactivate","variant":"outline","color":"primary"},on:{"click":function($event){return _vm.candidateState($event)}}},[_vm._v("Reactivate")]):_vm._e()],1):_vm._e()],1),(_vm.isPrivate)?_c('div',{staticClass:"position-absolute",staticStyle:{"top":"10px","left":"15px"}},[_c('i',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
        content: 'Private Profile',
        appendToBody: true,
        placement: 'right',
      }),expression:"{\n        content: 'Private Profile',\n        appendToBody: true,\n        placement: 'right',\n      }"}],staticClass:"fs-14 fa-solid fa-triangle-exclamation text-primary"})]):_vm._e(),(_vm.enableAddScratchPad)?_c('div',{staticClass:"position-absolute",staticStyle:{"top":"10px","right":"15px"}},[_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
        content: 'Add to Scratch Pad',
        appendToBody: true,
        placement: 'left',
      }),expression:"{\n        content: 'Add to Scratch Pad',\n        appendToBody: true,\n        placement: 'left',\n      }"}],staticClass:"text-primary cursor-pointer"},[_c('i',{staticClass:"fa-solid fa-circle-plus fs-14",on:{"click":_vm.addToScratch}})])]):_vm._e(),(_vm.inProcessCount)?_c('div',{staticClass:"position-absolute",staticStyle:{"top":"10px","left":"15px"}},[_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
        content: 'In Process',
        appendToBody: true,
        placement: 'left',
      }),expression:"{\n        content: 'In Process',\n        appendToBody: true,\n        placement: 'left',\n      }"}],staticClass:"text-primary font-weight-bold border-circle border-primary"},[_vm._v(" P ")])]):_vm._e(),(_vm.popupModal.isShowPopup)?_c('PopupModal',{attrs:{"modalTitle":_vm.popupModal.modalTitle,"modalColor":_vm.popupModal.modalColor,"modalContent":_vm.popupModal.modalContent,"modalLabel":_vm.popupModal.modalLabel,"isShowPopup":_vm.popupModal.isShowPopup,"popupModalCallBack":_vm.popupModalCallBack,"modalJob":_vm.popupModal.modalJob,"modalReasonType":_vm.popupModal.modalReasonType}}):_vm._e(),(_vm.previewModal.isShowPopup)?_c('PreviewModal',{attrs:{"modalTitle":_vm.previewModal.modalTitle,"modalColor":_vm.previewModal.modalColor,"isShowPopup":_vm.previewModal.isShowPopup,"buttons":_vm.previewModal.buttons,"modalCallBack":_vm.modalCallBack,"isPreview":_vm.previewModal.isPreview,"iframe":_vm.iframe,"size":_vm.previewModal.size,"closeOnBackdrop":_vm.previewModal.closeOnBackdrop}}):_vm._e(),(_vm.frontSheetModal.isShowPopup)?_c('CandidateFrontsheet',{attrs:{"isShowPopup":_vm.frontSheetModal.isShowPopup,"candidateDetail":_vm.frontSheetModal.candidate,"jobDetail":_vm.frontSheetModal.job,"frontSheetInfo":_vm.frontSheetModal.frontSheetInfo,"frontSheets":_vm.frontSheetModal.comments},on:{"modalCallBack":_vm.frontSheetModalCallBack}}):_vm._e(),(_vm.reqAgencyModal.isShowPopup)?_c('Modal',{attrs:{"modalTitle":_vm.reqAgencyModal.modalTitle,"modalColor":_vm.reqAgencyModal.modalColor,"modalContent":_vm.reqAgencyModal.modalContent,"isShowPopup":_vm.reqAgencyModal.isShowPopup,"buttons":_vm.reqAgencyModal.buttons,"modalCallBack":_vm.reqAgencyModalCallBack,"closeOnBackdrop":false}}):_vm._e(),(_vm.scratchpad.isShowPopup)?_c('ScratchPadModal',{attrs:{"todo":_vm.scratchpad.todo,"isShowPopup":_vm.scratchpad.isShowPopup},on:{"modalCallBack":_vm.scratchPadModalCallBack}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }