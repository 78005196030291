<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Professional Information Edit</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit()"
          v-if="activeTab === 1"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <CTabs variant="tabs" :active-tab.sync="activeTab">
          <CTab title="CV / Resume Preview">
            <div class="p-2">
              <CVPreview />
            </div>
          </CTab>
          <CTab title="Professional Information Edit">
            <div class="p-2">
              <ValidationObserver
                ref="editProfessionalInfo"
                v-slot="{ handleSubmit }"
              >
                <form
                  @submit.prevent="handleSubmit(onSubmit)"
                  name="editProfessionalInfo"
                >
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_candidate_type_id"
                          class="required col-lg-12 col-md-12"
                        >
                          Type
                          <!-- <span
                            class="hide-small-screen"
                            style="cursor: pointer; color: #e55353"
                          >
                            <i
                              class="fa fa-exclamation-triangle fa-lg"
                              @click="showConfirmModal()"
                            ></i>
                          </span> -->
                        </label>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              :disabled="disabled"
                              name="candidate_type_id"
                              :value="profile.candidate_type_id"
                              :options="
                                options && options['candidateType']
                                  ? options['candidateType']
                                  : []
                              "
                              @input="handleChangeSelect"
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <!-- <div class="hide-large-screen">
                      <CCol md="1" class="pl-1 pr-2">
                        <CRow class="row mb-4 pl-1 pt-2">
                          <span
                            class="pt-4 pl-1"
                            style="cursor: pointer; color: #e55353"
                          >
                            <i
                              class="fa fa-exclamation-triangle fa-lg"
                              @click="showConfirmModal()"
                            ></i>
                          </span>
                        </CRow>
                      </CCol>
                    </div> -->
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_current_job"
                          class="col-lg-12 col-md-12"
                          >Current Job Title</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <TextInput
                            name="current_job"
                            :value="profile.current_job"
                            @change="handleInput"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol v-if="isNonClinicAdmin" md="6">
                      <CRow>
                        <label class="col-lg-12 col-md-12 required"
                          >Level</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="level_id"
                              :value="profile.level_id"
                              @input="handleChangeSelect"
                              :options="
                                options && options['candidateLevel']
                                  ? options['candidateLevel']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                              :error="errors[0]"
                              :showTooltip="true"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_speciality_id"
                          class="required col-lg-12 col-md-12"
                          >Speciality</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="speciality_id"
                              :value="profile.speciality_id"
                              @input="handleChangeSelect"
                              :options="
                                options && options['speciality_id']
                                  ? options['speciality_id']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow
                        class="row mb-3"
                        v-if="options['sub_speciality_id'].length"
                      >
                        <label
                          name="lbl_sub_speciality_id"
                          class="col-lg-12 col-md-12"
                          >{{
                            isNurse ? `Area of Expertise` : `Sub Speciality`
                          }}</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <Select
                            name="sub_speciality_id"
                            :value="profile.sub_speciality_id"
                            @change="handleChangeSelect"
                            :options="
                              options && options['sub_speciality_id']
                                ? options['sub_speciality_id']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="true"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow
                    v-if="
                      profile.speciality_id
                        ? profile.speciality_id.label == 'Other - not listed'
                          ? true
                          : false
                        : false
                    "
                  >
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_other_speciality"
                          class="col-lg-12 col-md-12"
                          >Name Speciality</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <TextInput
                            name="other_speciality"
                            :value="profile.other_speciality"
                            @change="handleInput"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <div class="d-flex justify-content-center text-primary">
                    Insert Special Interest in Profile details Below
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </CTab>
        </CTabs>
      </div>
    </CModal>
  </div>
</template>
<script>
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import EditSection from "./EditSection";
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
export default {
  mixins: [EditSection],
  props: {
    candidateInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profile: {},
      payload: {},
      disabled: true,
    };
  },
  computed: {
    ...mapGetters([
      "candidateType",
      "specialityByTypeAndCountry",
      "subSpecialityFilterBySpeciality",
      "isFetchingFilter",
      "candidateLevelFilterByTypeCountry",
    ]),
    isFetching() {
      if (
        isObject(this.candidateInfo) &&
        !isEmptyObjectCheck(this.candidateInfo) &&
        !this.isFetchingFilter
      ) {
        let profileData = this.candidateInfo;
        this.profile = {
          ...profileData,
          candidate_type_id:
            profileData.candidate_type !== null
              ? {
                  code: profileData.candidate_type.candidate_type_id,
                  label: profileData.candidate_type.candidate_type,
                }
              : null,
          speciality_id:
            profileData.speciality !== null
              ? {
                  code: profileData.speciality.speciality_id,
                  label: profileData.speciality.speciality,
                }
              : null,
          sub_speciality_id:
            profileData.sub_speciality_id !== null
              ? {
                  code: profileData.sub_speciality.sub_speciality_id
                    ? profileData.sub_speciality.sub_speciality_id
                    : null,
                  label: profileData.sub_speciality.sub_speciality
                    ? profileData.sub_speciality.sub_speciality
                    : null,
                }
              : null,
          other_speciality: profileData.other_speciality,
          level_id: profileData.candidate_level  ? {
            code:profileData?.candidate_level?.level_id,
            label:profileData?.candidate_level?.candidate_level,
          }:null
        };
        return true;
      }
      return false;
    },
    options() {
      return {
        candidateType: this.candidateTypeReOrder || [],
        candidateLevel:
          this.candidateLevelFilterByTypeCountry({
            candidateType: this.profile?.candidate_type_id?.code,
            country_id: null,
          }) || [],
        speciality_id:
          this.specialityByTypeAndCountry({
            candidateType: this.profile.candidate_type_id?.code,
            country_id:
              this.candidateInfo.highest_qualification_country_id || null,
          }) || [],
        sub_speciality_id:
          this.subSpecialityFilterBySpeciality({
            speciality: this.profile.speciality_id?.code,
          }) || [],
      };
    },
    candidateTypeReOrder() {
      let sortBy = [1, 2, 8].reverse();
      return (
        this.candidateType &&
        this.candidateType.length &&
        this.candidateType.sort((a, b) => {
          return -sortBy.indexOf(a.code) - -sortBy.indexOf(b.code);
        })
      );
    },
    isNurse() {
      return this.profile?.candidate_type_id?.code
        ? this.profile.candidate_type_id.code === 2
        : false;
    },
    isNonClinicAdmin() {
      return this.profile?.candidate_type_id?.code
        ? this.profile.candidate_type_id.code === 6
        : false;
    },
  },
  methods: {
    ...mapActions(["initFetchProfessionalInfoOptions", "showToast"]),
    async onSubmit() {
      const isValid = await this.$refs.editProfessionalInfo.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      if (Object.keys(this.payload).length) {
        let isSpecialityChanged = [];
        for (const [key, value] of Object.entries(this.payload)) {
          if (key == "speciality_id" || key == "sub_speciality_id")
            isSpecialityChanged.push(key);
        }
        this.payload = {
          ...this.payload,
          candidate_uid: this.profile?.candidate_uid,
          candidate_type_id: this.profile?.candidate_type_id?.code,
          speciality_id: this.profile?.speciality_id?.code,
          sub_speciality_id: this.profile?.sub_speciality_id?.code || null,
          current_job: this.profile?.current_job,
          other_speciality:
            this.profile?.speciality_id?.label === "Other - not listed"
              ? this.profile?.other_speciality
              : null,
        };
        this.modalCallBack(true, this.payload, isSpecialityChanged);
      } else {
        this.modalCallBack(false);
      }
    },
    modalCallBack(action, payload, isSpecialityChanged) {
      this.$emit("modalCallBack", action, payload, isSpecialityChanged);
    },
    showConfirmModal() {
      if (
        confirm(
          "Are you sure to update the Candidate Type? This will Reset the Qualification and Speciality!"
        )
      ) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
  },
  mounted() {
    this.initFetchProfessionalInfoOptions();
  },
};
</script>
