<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Professional Information Nurse Edit</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit()"
          v-if="activeTab === 1"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <CTabs variant="tabs" :active-tab.sync="activeTab">
          <CTab title="CV / Resume Preview">
            <div class="p-2">
              <CVPreview />
            </div>
          </CTab>
          <CTab title="Professional Information Edit">
            <div class="p-2">
              <ValidationObserver
                ref="editProfessionalInfo"
                v-slot="{ handleSubmit }"
              >
                <form
                  @submit.prevent="handleSubmit(onSubmit)"
                  name="editProfessionalInfo"
                >
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_candidate_type_id"
                          class="required col-lg-12 col-md-12"
                        >
                          Type
                          <!-- <span
                            class="hide-small-screen"
                            style="cursor: pointer; color: #e55353"
                          >
                            <i
                              class="fa fa-exclamation-triangle fa-lg"
                              @click="showConfirmModal()"
                            ></i>
                          </span> -->
                        </label>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              :disabled="disabled"
                              name="candidate_type_id"
                              :value="profile.candidate_type_id"
                              :options="
                                options && options['candidateType']
                                  ? options['candidateType']
                                  : []
                              "
                              @input="handleChangeSelect"
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <!-- <div class="hide-large-screen">
                      <CCol md="1" class="pl-1 pr-2">
                        <CRow class="row mb-4 pl-1 pt-2">
                          <span
                            class="pt-4 pl-1"
                            style="cursor: pointer; color: #e55353"
                          >
                            <i
                              class="fa fa-exclamation-triangle fa-lg"
                              @click="showConfirmModal()"
                            ></i>
                          </span>
                        </CRow>
                      </CCol>
                    </div> -->
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_current_job"
                          class="col-lg-12 col-md-12"
                          >Current Job Title</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <TextInput
                            name="current_job"
                            :value="profile.current_job"
                            @change="handleInput"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol lg="6" md="6" sm="12">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_license_country_id"
                          class="col-lg-12 col-md-12"
                          >Licensed to Pratice In</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <Select
                            name="licensing_country_id"
                            :value="profile.licensing_country_id"
                            @input="handleChangeSelect"
                            :options="
                              options && options['locationInfo']
                                ? options['locationInfo']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                          />
                        </div>
                      </CRow>
                    </CCol>
                    <CCol lg="6" md="6" sm="12">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_license_level_id"
                          class="col-lg-12 col-md-12"
                          >Licensed to Practice as</label
                        >
                        <div class="col infoicon-multi-select">
                          <Select
                            name="license_level_id"
                            :value="profile.license_level_id"
                            @input="handleChangeSelect"
                            :options="
                              options &&
                              options['candidateLicenseLevelByCountry']
                                ? options['candidateLicenseLevelByCountry']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CRow>
                        <label class="col-lg-12 col-md-12 required"
                          >Nurse / Midwifery Level</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="level_id"
                              :value="profile.level_id"
                              @input="handleChangeSelect"
                              :options="
                                options && options['nurseLevel']
                                  ? options['nurseLevel']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                              :error="errors[0]"
                              :showTooltip="true"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6" v-if="isHavePGQualification">
                      <CRow name="registered_as" class="row mb-3">
                        <label class="required col-lg-12 col-md-12"
                          >Are you registered as a</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <div class="d-flex justify-content-between">
                            <label
                              >Speciality Nurse or a Nurse(Advanced
                              Nurse)Practitioner?</label
                            >
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <RadioButton
                                class="text-nowrap"
                                name="speciality_nurse_or_nurse_practitioner"
                                :value="
                                  profile[
                                    'speciality_nurse_or_nurse_practitioner'
                                  ]
                                "
                                :options="
                                  options && options['boolean']
                                    ? options['boolean']
                                    : []
                                "
                                @change="handleChangeRadioNurseType"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6" v-if="isUKQualification && isUKOrganisation">
                      <CRow>
                        <label class="col-md-12 col-lg-12"
                          >Current NHS Band</label
                        >
                        <CCol lg="12">
                          <Select
                            name="band_id"
                            :value="profile.band_id"
                            :options="
                              options && options['candidateBands']
                                ? options['candidateBands']
                                : []
                            "
                            @input="handleChangeSelect"
                            :clearable="true"
                          />
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </form>
              </ValidationObserver>
            </div>
          </CTab>
        </CTabs>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import _ from "lodash";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import EditSection from "./EditSection";
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
export default {
  mixins: [EditSection],
  props: {
    candidateInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    qualifications: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      profile: {},
      payload: {},
      disabled: true,
    };
  },
  computed: {
    ...mapGetters([
      "candidateType",
      "specialityByTypeAndCountry",
      "subSpecialityFilterBySpeciality",
      "isFetchingFilter",
      "candidateLevelFilterByTypeCountry",
      "nurseRegistrationType",
      "locationCountryInfo",
      "getCandidateBands",
      "isUKOrganisation",
    ]),
    isFetching() {
      if (
        isObject(this.candidateInfo) &&
        !isEmptyObjectCheck(this.candidateInfo) &&
        !this.isFetchingFilter
      ) {
        let profileData = this.candidateInfo;
        this.profile = {
          ...profileData,
          candidate_type_id:
            profileData.candidate_type !== null
              ? {
                  code: profileData.candidate_type.candidate_type_id,
                  label: profileData.candidate_type.candidate_type,
                }
              : null,
          level_id: profileData.candidate_level?.level_id
            ? {
                code: profileData?.candidate_level?.level_id,
                label: profileData?.candidate_level?.candidate_level,
              }
            : null,
          licensing_country_id:
            profileData.license_country_id !== null
              ? this.locationCountryInfo.filter(
                  ({ code }) => profileData.license_country_id == code
                )[0]
              : null,
          license_level_id:
            profileData.license_level_id !== null
              ? this.candidateLevelFilterByTypeCountry({
                  candidateType: profileData?.candidate_type_id,
                  country_id: profileData?.license_country_id,
                  is_licensing: true,
                }).filter(({ code }) => profileData.license_level_id == code)[0]
              : null,
          band_id: profileData.band
            ? {
                code: profileData?.band?.band_id,
                label: profileData?.band?.band_name,
              }
            : null,
        };
        this.nurseRegistrationType.forEach((element) => {
          this.profile[element] = false;
        });
        profileData?.nurse_registration_type &&
          profileData?.nurse_registration_type.forEach((element) => {
            this.profile[element] = true;
          });
        return true;
      }
      return false;
    },
    options() {
      return {
        candidateType: this.candidateTypeReOrder || [],
        nurseLevel:
          this.candidateLevelFilterByTypeCountry({
            candidateType: this.profile?.candidate_type_id?.code,
            country_id: null,
          }) || [],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        locationInfo: this.locationCountryInfo || [],
        candidateLicenseLevelByCountry:
          this.candidateLevelFilterByTypeCountry({
            candidateType: this.profile?.candidate_type_id?.code,
            country_id: this.profile?.licensing_country_id?.code,
            is_licensing: true,
          }) || [],
        candidateBands: this.getCandidateBands,
      };
    },
    candidateTypeReOrder() {
      let sortBy = [1, 2, 8].reverse();
      return (
        this.candidateType &&
        this.candidateType.length &&
        this.candidateType.sort((a, b) => {
          return -sortBy.indexOf(a.code) - -sortBy.indexOf(b.code);
        })
      );
    },
    nurseTypes() {
      return (
        this.nurseRegistrationType
          .filter(
            (val) => val != "speciality_nurse" && val != "nurse_practitioner"
          )
          .map((val) => ({
            code: val,
            label:
              val && val == "any_higherqualification_related_to_practice"
                ? "Higher Qualifications are related to Speciality or Nurse Practitioner practice"
                : _.startCase(val),
          })) || []
      );
    },
    isOnlyNursingQualification() {
      return (
        (this.qualifications.length === 1 &&
          [423, 425].includes(this.qualifications[0].qualification_id)) ||
        false
      );
    },
    isHavePGQualification() {
      return this.qualifications.some(({ isPG }) => isPG);
    },
    isUKQualification() {
      return (
        (this.qualifications &&
          this.qualifications.some(({ country_id }) => country_id === 182)) ||
        false
      );
    },
  },
  methods: {
    ...mapActions(["initFetchProfessionalInfoOptions", "showToast"]),
    async onSubmit() {
      const isValid = await this.$refs.editProfessionalInfo.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      if (Object.keys(this.payload).length) {
        let isSpecialityChanged = [];
        for (const [key, value] of Object.entries(this.payload)) {
          if (key == "speciality_id" || key == "sub_speciality_id")
            isSpecialityChanged.push(key);
        }
        this.payload = {
          ...this.payload,
          candidate_uid: this.profile?.candidate_uid,
          candidate_type_id: this.profile?.candidate_type_id?.code,
          current_job: this.profile?.current_job,
          level_id: this.profile?.level_id?.code,
          license_country_id: this.profile?.licensing_country_id?.code || null,
          license_level_id: this.profile?.license_level_id?.code || null,
          band_id: this.profile?.band_id?.code || null,
        };
        let finalPayload = { ...this.payload };
        let nurse_registration_type = [];
        if (
          this.candidateInfo?.nurse_registration_type &&
          this.candidateInfo?.nurse_registration_type.length &&
          this.candidateInfo?.nurse_registration_type.includes(
            "any_higherqualification_related_to_practice"
          )
        ) {
          nurse_registration_type = [
            ...nurse_registration_type,
            "any_higherqualification_related_to_practice",
          ];
        }
        if (this.profile["speciality_nurse_or_nurse_practitioner"]) {
          nurse_registration_type = [
            ...nurse_registration_type,
            "speciality_nurse_or_nurse_practitioner",
          ];
        }
        delete finalPayload["speciality_nurse_or_nurse_practitioner"];

        finalPayload["nurse_registration_type"] = nurse_registration_type.length
          ? nurse_registration_type
          : null;
        this.modalCallBack(true, finalPayload, isSpecialityChanged);
      } else {
        this.modalCallBack(false);
      }
    },
    modalCallBack(action, payload, isSpecialityChanged) {
      this.$emit("modalCallBack", action, payload, isSpecialityChanged);
    },
    showConfirmModal() {
      if (
        confirm(
          "Are you sure to update the Candidate Type? This will Reset the Qualification and Speciality!"
        )
      ) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    handleChangeRadioNurseType(name, value) {
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }

      Vue.set(this.profile, name, val);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
      // if (this.nurseRegistrationType.includes(name) && val) {
      //   this.nurseRegistrationType.forEach((element) => {
      //     name !== element
      //       ? this.handleChangeRadioNurseType(element, false)
      //       : null;
      //   });
      // }
    },
    handleChangeMultiSelectAsArrayNurse(name, value) {
      if (
        name === "other_areas_of_expertise_ids" &&
        value &&
        value.some(({ code }) => code === this.profile?.speciality_id?.code)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message:
            "Speciality already exists! Please select another Speciality",
        });
        return;
      }
      Vue.set(this.profile, name, value);
      this.payload = {
        ...this.payload,
        [name]: value
          ? value.map((v) => {
              return v.id || v.code || v;
            })
          : null,
      };
    },
    handleChangeSelectNurse(name, value) {
      if (
        name === "speciality_id" &&
        this.profile?.other_areas_of_expertise_ids &&
        this.profile?.other_areas_of_expertise_ids.some(
          ({ code }) => value?.code === code
        )
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message:
            "Speciality already exists! Please select another Speciality",
        });
        return;
      }
      Vue.set(this.candidate, name, value);
    },
  },
  mounted() {
    this.initFetchProfessionalInfoOptions();
  },
};
</script>
